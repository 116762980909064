<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card max-width="600" grid-list-lg>
      <ModelTitle
        :color="$style.purchase.titlebar"
        title="Paying Invoice"
        @close="close()"
      />

      <v-card-text>
        <v-container class="pa-0" grid-list-lg>
          <v-card-text>
            <v-layout row wrap justify-space-between>
              <v-flex xs6>
                <div>Vendor ID</div>
                <div class="font-weight-medium title">
                  {{ Vendor.localId }}
                </div>
              </v-flex>
              <v-flex shrink>
                <div>Balance</div>
                <div class="font-weight-medium title">
                  {{ Vendor.balance | currency }}
                </div>
              </v-flex>
              <v-flex xs8>
                <div>Vendor Name</div>
                <div class="font-weight-medium title text-capitalize">
                  {{ Vendor.name }}
                </div>
              </v-flex>
              <v-flex shrink>
                <div>Paying</div>
                <div class="font-weight-medium title">
                  {{ total | currency }}
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap justify-space-between>
              <v-flex xs12 sm4>
                <DateSelector label="Payment Date" v-model="Payment.date" />
              </v-flex>

              <v-flex xs12 sm4>
                <v-select
                  dense
                  outlined
                  :items="paymentMethods"
                  v-model="Payment.paymentMethod"
                  label="Payment Method"
                  item-text="name"
                  hide-details
                  return-object
                ></v-select>
              </v-flex>

              <v-flex xs12 sm4>
                <v-text-field
                  type="number"
                  dense
                  outlined
                  class="right-input"
                  hide-details
                  label="Total Payment"
                  @focus="$event.target.select()"
                  v-model="PurchaseTotal"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  dense
                  outlined
                  v-model="Payment.note"
                  label="Memo"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <div class="text-center">
              <span
                >Balance: {{ balance | currency }}/ Total:
                {{ PurchaseTotal | currency }}/ Paying Total:
                {{ total | currency }}</span
              >
            </div>
          </v-card-text>
          <v-card
            :color="color"
            :disabled="PurchaseTotal === 0"
            outlined
            v-if="Purchase"
          >
            <v-card-text>
              <v-layout row wrap align-center>
                <v-flex xs7>
                  <v-select
                    clearable
                    dense
                    outlined
                    :items="Purchase"
                    hide-details
                    label="Select Purchase"
                    item-text="localId"
                    return-object
                    v-model="selectedPurchase"
                  >
                    <template slot="item" slot-scope="{ item }">
                      <span>{{ item.localId }}</span>
                      <v-spacer></v-spacer>
                      <span>{{ item.due | currency }}</span>
                    </template>
                    <template v-slot:prepend-item v-if="Purchase.length !== 0">
                      <v-list-item @click="payAll">
                        <v-list-item-content>
                          <v-list-item-title>Pay All</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          {{ balance | currency }}
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    class="right-input"
                    hide-details
                    label="Payment"
                    @focus="$event.target.select()"
                    v-model="amount"
                  ></v-text-field>
                </v-flex>
                <v-flex shrink>
                  <v-btn @click="add" color="success"> add </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <v-list class="list-350">
            <div v-for="(row, index) in Payment.PaymentDetails" :key="index">
              <v-list-item>
                <v-list-item-avatar>
                  <v-btn icon text color="red" @click="remove(index)"
                    ><v-icon>delete</v-icon></v-btn
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ row.localId }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="title">{{ row.amount | currency }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < Payment.PaymentDetails.length"
                :key="index"
              >
              </v-divider>
            </div>
          </v-list>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="close()">Close</v-btn>
        <v-btn
          color="info"
          :disabled="Payment.PaymentDetails.length === 0"
          :loading="loading"
          @click="submit()"
          >{{ total | currency }} Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import purchaseService from "@/modules/Inventory/Purchase/service";
import vendorService from "@/modules/Inventory/Vendor/service";
import paymentService from "../service";
import DateSelector from "@/components/DateSelector";

export default {
  name: "create-purchase-payment",
  props: {
    createDialog: {
      default: false,
    },
    VendorId: {
      default: null,
    },
  },
  components: {
    DateSelector,
  },
  data() {
    return {
      PurchaseTotal: 0,
      selectedPurchase: null,
      Purchase: null,
      loading: false,
      dialog: false,
      amount: 0,
      Vendor: {
        name: null,
        balance: 0,
        localId: null,
      },
      paymentMethods: [
        "Cash",
        "Check",
        "Paypal",
        "Behalf",
        "COD",
        "Debit Card",
        "Credit Card",
        "Wire",
        "Quick Pay",
        "Cash App",
        "Exchange",
        "Other",
      ],
      Payment: {
        VendorId: 0,
        date: this.$moment().toISOString(),
        paymentMethod: "Cash",
        PaymentDetails: [],
      },
    };
  },
  computed: {
    color() {
      let value = "indigo lighten-5";
      if (this.balance === 0) {
        value = "green lighten-5";
      } else {
        if (this.balance < 0) {
          value = "red lighten-5";
        } else {
          value = "indigo lighten-5";
        }
      }

      return value;
    },
    total() {
      let value = 0;
      this.Payment.PaymentDetails.map((row) => {
        value += parseFloat(row.amount);
      });

      return value;
    },
    balance() {
      // return this.PurchaseTotal - this.total;
      return this.Vendor.balance
    },
  },
  watch: {
    selectedPurchase(val) {
      if (this.balance > val.due) {
        this.amount = val.due;
      } else {
        if (this.balance <= 0) {
          this.amount = val.due;
        } else {
          this.amount = this.balance;
        }
      }
    },
    createDialog(val) {
      this.dialog = val;
      if (val) {
        this.loadOpenPurchase();
        this.getVendor();
      }
    },
  },
  methods: {
    async payAll() {
      await Promise.all(
        this.Purchase.map((row) => {
          let x = row;
          x.amount = row.due;
          x.PurchaseId = row.id;
          this.Payment.PaymentDetails.push(row);
        })
      );
      this.Purchase = [];
      this.emptybar();
    },
    add() {
      if (this.amount === 0) return false;
      const index = this.Purchase.findIndex(
        (x) => x.id === this.selectedPurchase.id
      );
      let row = this.Purchase[index];

      if (this.amount > this.Purchase[index].due) {
        this.$swal({
          title: "Exceeded!",
          text: `Payment can't be more then ${this.Purchase[index].due}`,
          icon: "warning",
        });
      } else {
        row.amount = this.amount;
        row.PurchaseId = row.id;
        this.Payment.PaymentDetails.push(row);
        this.Purchase.splice(index, 1);
        this.amount = 0;
      }
    },
    emptybar() {
      this.amount = 0;
      this.selectedPurchase = null;
    },
    remove(i) {
      this.Purchase.push(this.Payment.PaymentDetails[i]);
      this.Payment.PaymentDetails.splice(i, 1);
    },
    getVendor() {
      return vendorService.getById(this.VendorId).then((response) => {
        this.Vendor = response.data;
        this.Payment.VendorId = this.Vendor.id;
        return response;
      });
    },
    loadOpenPurchase() {
      this.loading = true;
      return purchaseService
        .getAll({ due: true, VendorId: this.VendorId })
        .then((response) => {
          this.loading = false;
          this.Purchase = response.data;
          this.PurchaseTotal = 0
          this.Purchase.map((row) => {
            this.PurchaseTotal += row.due;
          });
          return response;
        });
    },
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Payment = {
        VendorId: 0,
        date: this.$moment().toISOString(),
        paymentMethod: "Cash",
        PaymentDetails: [],
      };
    },
    submit() {
      this.$swal({
        title: "Saving Payment",
        text: `You are paying ${this.$options.filters.currency(this.total)}? `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          // this.$Progress.start();
          this.loading = true;
          // console.log("payment", this.Payment);
          return paymentService
            .create(this.Payment)
            .then((result) => {
              if (result.status) {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .fire({
                    icon: "success",
                    title: "Payment was made",
                  });
                this.loading = false;
                this.empty();
                this.$emit("submit");
                this.$events.emit("purchasePaymentListEvent");
                this.$events.emit("VendorProfileEvent");
                this.$events.emit("purchaseListEvent");
                this.close();
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "error",
                  title: err.data.message,
                });
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
