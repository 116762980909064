import backofficeService from '../../../api/backoffice'

export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */


  create(data) {
    return backofficeService.Api().post('purchase', data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  fixAvgCost(id, params = {}) {
    return backofficeService.Api().get(`purchase/fixAvgCost/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  update(id, data) {
    return backofficeService.Api().put(`purchase/${id}`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  restock(data) {
    return backofficeService.Api().put('purchase/restock', data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  markItSold(data) {
    return backofficeService.Api().put('purchase/markItSold', data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },


  getAll(params) {
    return backofficeService.Api().get('purchase', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getLastPurchase(params) {
    return backofficeService.Api().get('purchaseDetail/last', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getPurchaseDetail(params) {
    return backofficeService.Api().get('purchaseDetail', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getSerialVault(params) {
    return backofficeService.Api().get('serialVault', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getRestock(params) {
    return backofficeService.Api().get('purchase/restock', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getAvailableSerial(params) {
    return backofficeService.Api().get('purchaseDetail/availableSerial', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  search(params) {
    return backofficeService.Api().get(`purchase/search`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  searchLog(params) {
    return backofficeService.Api().get(`purchase/searchLog`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },




  updateDetailRow(id, data) {
    return backofficeService.Api().put(`purchaseDetail/${id}`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },


  getById(id, params = {}) {
    return backofficeService.Api().get(`purchase/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  /**
   * Delete product
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`purchase/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

}